import React, { useEffect, lazy, Suspense } from "react";
import { useAuth0 } from "./auth/react-auth0-spa";
import history from "./utils/history";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Midgard
import {
  Fonts,
  LoadingScreen,
  GlobalStyles,
  ThemeProvider as ThemeProviderCH
} from "@clearhaus/design-system";

//Styled-components
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./global/globalStyle";

//Global
import settings from "./global/settings";
import anchors from "./global/anchors";
import Wrapper from "./components/wrapper/wrapper";
import VerifyEmail from "./pages/verifyEmail";
import SignUpError from "./pages/signUpError";
import Page404 from "./pages/404"
import Login from "./auth/login/login";
import Pending from "./components/pending/pending"

//Analytics
import { GAinit } from './components/tracking/tracking';

//Pages
const ApiKeysPage = lazy(() => import(`./pages/apiKeysPage`));
const RequestsPage = lazy(() => import(`./pages/requestsPage`));
const AccountDetails = lazy(() => import(`./components/accountDetails/accountDetails`));

const AccountDetailsComponent = <AccountDetails />
const ApiKeysPageComponent = <ApiKeysPage />;
const RequestsPageComponent = <RequestsPage />;
const PendingComponent = <Pending />

const App = () => {
  useEffect(() => {
    GAinit()
  }, []);

  const { loading, user, fetching, isAuthenticated, isSwitched, isSignUpFailed } = useAuth0();

  if (loading || isSwitched) {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }

  if (isSignUpFailed) {
    return (
      <div>
        <ThemeProviderCH defaults={settings}>
          <ThemeProvider theme={settings}>
            <Fonts />
            <GlobalStyles />
            <GlobalStyle />
            <SignUpError />
          </ThemeProvider>
        </ThemeProviderCH>
      </div>
    );
  }

  if (user !== undefined && user.email_verified === false && !isSignUpFailed) {
    return (
      <div>
        <ThemeProviderCH defaults={settings}>
          <ThemeProvider theme={settings}>
            <Fonts />
            <GlobalStyles />
            <GlobalStyle />
            <VerifyEmail />
          </ThemeProvider>
        </ThemeProviderCH>
      </div>
    );
  }

  if (fetching && isAuthenticated) {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }

  return (
    <>
      <ThemeProviderCH defaults={settings}>
        <ThemeProvider theme={settings}>
          <Fonts />
          <GlobalStyles />
          <GlobalStyle />
          <Router history={history}>
            <Switch>
              <Route path="/" exact component={Login} />
              <Route
                path="/:environment/accounts/:accountId/keys"
                exact
                render={props => (
                  <Wrapper
                    {...props}
                    children={ApiKeysPageComponent}
                    anchors={anchors}
                    environment={props.match.params.environment}
                    company={props.match.params.company}
                  />
                )}
              />
              <Route
                path="/:environment/accounts/:accountId/requests/:requestId"
                exact
                render={props => (
                  <Wrapper
                    {...props}
                    children={RequestsPageComponent}
                    anchors={anchors}
                    company={props.match.params.company}
                    environment={props.match.params.environment}
                  />
                )}
              />
              <Route
                path="/:environment/accounts/:accountId/requests"
                exact
                render={props => (
                  <Wrapper
                    {...props}
                    children={RequestsPageComponent}
                    anchors={anchors}
                    company={props.match.params.company}
                    environment={props.match.params.environment}
                  />
                )}
              />
              <Route
                path="/:environment/accounts/:accountId"
                exact
                render={props => (
                  <Wrapper
                    {...props}
                    children={AccountDetailsComponent}
                    anchors={anchors}
                    company={props.match.params.company}
                    environment={props.match.params.environment}
                  />
                )}
              />
              <Route
                path="/:environment/pending"
                exact
                render={props => (
                  <Wrapper
                    {...props}
                    children={PendingComponent}
                    anchors={anchors}
                    company={props.match.params.company}
                    environment={props.match.params.environment}
                  />
                )}
              />
              <Route render={props => <Page404 {...props} />} />
            </Switch>
          </Router>
        </ThemeProvider>
      </ThemeProviderCH>
    </>
  );
};

export default App;
