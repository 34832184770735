import { KeyIcon, FileTextIcon } from "@clearhaus/design-system";

export default [
  {
    url: "keys",
    icon: KeyIcon,
    name: "API Keys"
  },
  {
    url: "requests",
    icon: FileTextIcon,
    name: "Requests"
  }
];
