import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Client, bearerAuth } from 'ketting';
import { useAuth0 } from '../auth/react-auth0-spa';
import * as Sentry from "@sentry/browser";

// Exports
export const KettingContext = React.createContext();
export const useKetting = () => useContext(KettingContext);

const getInitialAccountResource = async (ketting, setAccountResource, userRoles, accountId, isSwitched, switchAccountId) => {
    const kettingAccountId = (isSwitched ? switchAccountId : accountId)
    const accountResource = await ketting.follow('account', { id: `${kettingAccountId}` }).catch((error) => Sentry.captureException(error))
    setAccountResource(accountResource)
    sessionStorage.setItem("selectedAccount", accountResource.uri)
}

const switchAccountResource = async (URI, setAccountResource) => {
    setAccountResource(URI)
}

export const KettingProvider = ({ children }) => {
    const { token, userRoles, isSwitched, switchAccountId } = useAuth0();
    const [accountResource, setAccountResource] = useState()
    const [isResourceLoading, setIsResourceLoading] = useState(true)
    const [accountId, setAccountId] = useState()
    const [accounts, setAccounts] = useState([])
    const URL = (window.location.href.split("/")[3] === "production" ? process.env.REACT_APP_PRODUCTION_ROOT : process.env.REACT_APP_SANDBOX_ROOT)
    const ketting = useMemo(() => {
        const ketting = new Client(URL)
        ketting.use(bearerAuth(`${token}`))

        return ketting
    }, [token])


    useEffect(() => {
        if (!isResourceLoading || sessionStorage.getItem("hasLoggedIn")) {
            const temporaryURL = window.location.href
            const temporaryAccountId = temporaryURL.split("/")[5]

            if (temporaryAccountId !== undefined) {
                setAccountId(temporaryAccountId)
            }

            if (token !== undefined && accountId !== undefined) {
                getInitialAccountResource(ketting, setAccountResource, userRoles, accountId, isSwitched, switchAccountId)
            }
        }
    }, [token, ketting, userRoles, accountId, isResourceLoading]);

    return (
        <>
            <KettingContext.Provider
                value={{
                    setAccountResource,
                    setIsResourceLoading,
                    setAccountId,
                    ketting,
                    accountResource,
                    switchAccountResource,
                    accounts,
                    setAccounts
                }}
            >
                {children}
            </KettingContext.Provider>
        </>
    );
};

