import ReactGA from 'react-ga';

// Initialize Google Analytics
export const GAinit = () => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID)
}

// Method to track user navigation
export const GApageView = (...args) => {
    const environment = args[0]
    const accountId = args[1]
    const requestId = args[2]
    var page = window.location.pathname
    if (page.indexOf(environment + '/accounts/' + accountId) > -1) {
        page = page.replace('accounts/' + accountId, 'accounts')
        if (page.indexOf('requests/' + requestId) > -1) {
            page = page.replace('requests/' + requestId, 'requests/request')
        }
    }
    ReactGA.pageview(page + window.location.search)
}

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const GAevent = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };
