import React, { useEffect, useContext } from "react";
import { Grid, Card, Heading, BoxEdges, ThemeContext, Button } from "@clearhaus/design-system";
import { Link } from "react-router-dom";
import { LinkNotFound } from "ketting";
import { GApageView } from '../components/tracking/tracking';

const GridHeight = {
  height: "100vh"
};


const App = () => {
  const { defaults } = useContext(ThemeContext);

  useEffect(() => {
    GApageView();
  })
  return (
    <Grid container fluid>
      <Grid row justify={"center"} noGutters>
        <Grid item auto>
          <Grid
            row
            direction={"column"}
            justify={"center"}
            style={GridHeight}
            noGutters
          >
            <Grid item auto>
              <Card>
                <Card.Content>
                  <Heading level={1}>
                    <BoxEdges m={defaults.spacing[16]}>404 page not found</BoxEdges>
                    <Link to={"/"}><Button >Go back</Button></Link>
                  </Heading>
                </Card.Content>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>)
}

export default App;
