import React, { useMemo, useContext } from "react";
import MenuWrapper, { MenuItemGrid } from "./menuItem.style";
import { ThemeContext } from "@clearhaus/design-system";

const MenuItem = ({ Icon, text, onClick }) => {
  const { defaults } = useContext(ThemeContext)

  const fontWeight = useMemo(() => ({
    fontWeights: defaults.fontWeights.medium
  }), []);

  return (
    <MenuWrapper onClick={onClick}>
      <>
        <MenuItemGrid>
          {Icon !== undefined ? (
            <>
              <Icon color={`${props => props.theme.colors.neutrals[400]}`} />
              <p style={fontWeight}>{text}</p>{" "}
            </>
          ) : (
              <>
                <p style={fontWeight}>{text}</p>{" "}
              </>
            )}
        </MenuItemGrid>
      </>
    </MenuWrapper>
  );
};

export default MenuItem;
