import React, { useContext } from "react";
import { Grid, Card, Heading, Button, BoxEdges, ThemeContext } from "@clearhaus/design-system";
import { useAuth0 } from "../auth/react-auth0-spa";

const GridHeight = {
  height: "100vh"
};

const VerifyEmail = () => {
  const { logout } = useAuth0();
  const { defaults } = useContext(ThemeContext);
  return (
    <>
      <Grid container fluid>
        <Grid row justify={"center"} noGutters>
          <Grid item auto>
            <Grid
              row
              direction={"column"}
              justify={"center"}
              style={GridHeight}
              noGutters
            >
              <Grid item auto>
                <Card>
                  <Card.Content>
                    <Heading level={1}>
                      <BoxEdges mb={defaults.spacing[32]}>Please verify your e-mail and reload this page</BoxEdges>
                    </Heading>
                    <Button onClick={logout}>Reload page</Button>
                  </Card.Content>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default VerifyEmail;
