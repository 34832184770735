import React from "react";
import { Grid, Loader } from "@clearhaus/design-system";

const fixedHeight = {
    height: "100vh"
}

const CenteredLoader = () => {
    return (
        <Grid container>
            <Grid row justify={"center"} alignItems={"center"} style={fixedHeight}>
                <Loader size={42} />
            </Grid>
        </Grid>
    );
};

export default CenteredLoader;
