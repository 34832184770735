const sortAccountList = (a,b) => {
  let domainA = a.data.email.split('@')[1].split('.')[0].toUpperCase(); // Ignore casing
  let domainB = b.data.email.split('@')[1].split('.')[0].toUpperCase();
  if (domainA < domainB) {
    return -1;
  }
  if (domainA > domainB) {
    return 1;
  }
  return 0; // If names are equal
}

export default sortAccountList;
