import { defaults } from "@clearhaus/design-system";

export const fontFamilies = {
  primary: "'Calibre', sans-serif",
  secondary: '"Circular", "Arial", sans-serif',
  mono: "SourceCode",
}

export default {
  ...defaults,
  colors: {
    primary: {
      900: "#162d30",
      700: "#264d52",
      600: "#2c5b60",
      500: "#39757c",
      400: "#508d94",
      300: "#e1f0f2",
      200: "#ebf3f4",
      100: "#ebf3f4",
      0: "#e1eaeb"
    },
    neutrals: {
      900: "#1d2021",
      800: "#393e41",
      700: "#4d5357",
      600: "#757575",
      500: "#a0a0a0",
      400: "#bbbbbb",
      300: "#f2f2eb",
      200: "#FAFCFC",
      100: "#FFFFFF"
    },
    accents: {
      success: {
        900: "#175439",
        700: "#299867",
        500: "#4BCE93",
        300: "#A3E6C8",
        200: "#CFF2E2",
        100: "#DFF6EC"
      },
      warning: {
        900: "#604C20",
        700: "#C4A650",
        500: "#F2C359",
        400: "#F6E2A7",
        300: "#FBECC1",
        200: "#FDF5E3",
        100: "#FEFBF1"
      },
      error: {
        900: "#602020",
        700: "#A73029",
        600: "#B92D2E",
        500: "#CE3334",
        400: "#F57070",
        300: "#F2A7A5",
        200: "#F6E7E6",
        100: "#FCF3F3"
      },
      notice: {
        900: "#94583d",
        700: "#b86d4b",
        600: "#d47b53",
        500: "#ea885b",
        300: "#e88f66",
        200: "#e69773",
        100: "#fbfdfd"
      }
    },
    gradients: {
      ...defaults.colors.gradients,
      standard: "linear-gradient(90deg, #5F79E6 0%, #677EDC 100%)"
    }
  },
  theme: {
    fontFamilies,
  }
};
