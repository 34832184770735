import React, { useState, useEffect, Suspense } from "react";
import WrapperStyle, {
  MainWrapper,
  SidebarWrapper,
  IconWrapper
} from "./wrapper.style";
import Sidebar from "../sidebar/sidebar";
import { ArrowRightIcon } from "@clearhaus/design-system";
import CenteredLoader from "../centeredLoader/centeredLoader"

//Custom hook to calculate window width, and return the width of the sidebar.
const useWindowWidth = open => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return open ? 304 : width >= 768 ? 304 : 30;
};

const Wrapper = ({ children, anchors, company, environment, request }) => {
  const [toggle, setToggle] = useState({ open: false });

  const sidebarWidth = useWindowWidth(toggle.open);

  return (
    <WrapperStyle>
      <SidebarWrapper sidebarWidth={sidebarWidth}>
        <Sidebar
          anchors={anchors}
          environment={environment}
          request={request}
          company={company}
        />
      </SidebarWrapper>
      <IconWrapper
        sidebarWidth={sidebarWidth}
        onClick={() => setToggle({ open: !toggle.open })}
      >
        <ArrowRightIcon />
      </IconWrapper>

      <MainWrapper sidebarWidth={sidebarWidth}>

        <Suspense fallback={<CenteredLoader />}>{children}</Suspense></MainWrapper>
    </WrapperStyle>
  );
};

export default Wrapper;
