import React, { useContext, useCallback } from "react";
import SidebarWrapper, {
  SelectWrapper,
  BottomWrapper,
  TopWrapper
} from "./sidebar.style";
import {
  Select,
  SelectOption,
  ThemeContext,
  GlobeIcon,
  CrossIcon,
  List,
  GlobalStyles,
  BoxEdges
} from "@clearhaus/design-system";
import { useHistory, useLocation, useParams } from "react-router-dom";
import MenuItem from "../menuItem/menuItem";
import LogoComp from "../logo/logo";
import { useAuth0 } from "../../auth/react-auth0-spa";
import isDashboardPersonnel from "../../utils/rolesHelper";
import { GAevent } from '../tracking/tracking';

const Sidebar = ({ anchors }) => {
  const history = useHistory();
  const location = useLocation();
  const { environment, accountId } = useParams();
  const { defaults } = useContext(ThemeContext);
  const { logout, changeAudience, userRoles } = useAuth0();

  const setEnvironment = useCallback(event => {
    const selectedEnvironment = event.target.value;
    let currentEnvironment = window.location.pathname.split("/")[1]
    changeAudience(selectedEnvironment, currentEnvironment);
  }, [accountId, changeAudience, history, location.pathname]);

  const menuItemOnClick = link => () => {
    if (link.charAt(0) === "/") {
      history.push(link);
    } else {
      if (link === "https://docs.3dsecure.io/3dsv2/") {
        GAevent(
          "Menu item",
          "See 3DSS Documentation",
          "documentation"
          );
      }
      window.open(link);
    }
  };

  return (
    <>
      <GlobalStyles />
      <SidebarWrapper>
        <TopWrapper>
          <LogoComp />
          <BoxEdges mt={32}>
            <SelectWrapper>
              <Select placeholder={environment} onChange={setEnvironment}>
                <SelectOption value="sandbox">Sandbox</SelectOption>
                <SelectOption value="production">Production</SelectOption>
              </Select>
            </SelectWrapper>
          </BoxEdges>

          <BoxEdges mt={32}>
            <List color={defaults.colors.primary[500]}>
              {((environment === "production" && userRoles.includes("production-user")) || environment === "sandbox") || isDashboardPersonnel(userRoles) ? <>
                {anchors
                  ? anchors.map((e, i) => (
                    <List.Item key={`nav-item-${i}`}>
                      <List.Item.Content
                        primary={
                          <MenuItem
                            Icon={e.icon}
                            text={e.name}
                            onClick={
                              menuItemOnClick(
                                `/${environment}/accounts/${accountId}/${e.name === "Requests" ? "requests" : "keys"
                                }`
                              )
                            }
                          />
                        }
                      />
                    </List.Item>
                  ))
                  : ""}</> : ""}
            </List>
          </BoxEdges>
        </TopWrapper>

        <BottomWrapper>
          <List>
            <List.Item>
              <List.Item.Content
                primary={
                  <MenuItem
                    Icon={GlobeIcon}
                    text={"3DSS Documentation"}
                    onClick={
                      menuItemOnClick(
                        "https://docs.3dsecure.io/3dsv2/"
                      )
                    }
                  />
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Content
                primary={""}
                secondary={
                  <MenuItem
                    Icon={CrossIcon}
                    text={"Logout"}
                    onClick={logout}
                  />
                }
              />
            </List.Item>
          </List>
        </BottomWrapper>
      </SidebarWrapper>
    </>
  );
};

export default Sidebar;
