import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: -32px;
`;

const MainWrapper = styled.div`
  width: calc(
    100% -
      (
        ${props => props.sidebarWidth}px +
          (${props => props.theme.spacing[32]}px * 2)
      )
  );
  background-color: ${props => props.theme.colors.neutrals[200]};
  min-height: 100vh;
  padding: ${props => props.theme.spacing[32]}px;
  z-index: 1;
  margin-bottom: -32px;
  padding-bottom: 0px;
  position: relative;
  margin-left: 304px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: ${props => props.theme.spacing[32]}px;
  }
`;

const SidebarWrapper = styled.div`
  width: ${props => props.sidebarWidth}px;
  box-shadow: ${props => props.theme.shadows.small.wide};
  z-index: 2;
  min-height: 100vh;
  background-color: #fff;
  position: fixed;
  transition: all 0.3s ease-in;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    position: fixed;
  }
`;

const IconWrapper = styled.div`
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 200px;
  position: fixed;
  top: calc(50% - 20px);
  left: calc(${props => props.sidebarWidth}px - 20px);
  background-color: ${props => props.theme.colors.primary[100]};
  z-index: 2;
  transition: all 0.3s ease-in;
  transform: rotate(${props => (props.sidebarWidth === 30 ? "180deg" : "0")});
  svg {
    color: ${props => props.theme.colors.primary[500]};
    margin: 8px;
  }
  @media only screen and (max-width: 768px) {
    display: block;
  }
  @media only screen and (max-width: 350px) {
    left: calc(${props => props.sidebarWidth}px - 40px);
  }
`;

export default Wrapper;
export { MainWrapper, SidebarWrapper, IconWrapper };
