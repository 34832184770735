import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "./auth/react-auth0-spa";
import { KettingProvider } from "./ketting/KettingContext";
import history from "./utils/history";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if (process.env.REACT_APP_ENVIRONMENT !== "local") {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: 'https://99622b3753c74a7286db4eeec55d1d53@o475056.ingest.sentry.io/5512504',
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      return breadcrumb.category === "ui.click" ? null : breadcrumb;
    },
    tracesSampleRate: 1.0,
  });
}

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_DOMAIN}
    client_id={process.env.REACT_APP_CLIENT_ID}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={process.env.REACT_APP_SANDBOX_AUDIENCE}
  >
    <KettingProvider>
      <App />
    </KettingProvider>
  </Auth0Provider>,
  document.getElementById("root")
);
