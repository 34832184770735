import React, { useState, useEffect, useContext, useCallback } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import { shallowEqual } from "fast-equals";
import { Client, bearerAuth } from 'ketting';
import history from "../utils/history"
import isDashboardPersonnel from "../utils/rolesHelper";
import sortAccountList from "../utils/sortAccounts";
import * as Sentry from "@sentry/browser";

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

const getAccountId = async (token, setSwitchAccountId, setIsSwitched, switchEnvironment, redirectLoginToProduction) => {
  const URL = (switchEnvironment === "production" ? process.env.REACT_APP_PRODUCTION_ROOT : process.env.REACT_APP_SANDBOX_ROOT)
  const ketting = new Client(URL)
  ketting.use(bearerAuth(`${token}`))
  const rootResource = await ketting.go()
  const accountsResource = await rootResource.follow('accounts').followAll('accounts').catch((error) => Sentry.captureException(error))
  const accounts = []

  for (const accountResource of accountsResource) {
    const account = await accountResource.get()
    accounts.push(account)
  }

  accounts.sort(sortAccountList);

  const account = accounts[0]

  let tempURLArray = window.location.pathname.split("/")
  tempURLArray[3] = account.data.id
  if(redirectLoginToProduction){
    tempURLArray[2] = 'accounts'
    tempURLArray[4] = 'keys'
  }
  tempURLArray[1] = switchEnvironment
  const newURL = tempURLArray.join("/")
  history.push(newURL)

  setSwitchAccountId(account.data.id)
  setIsSwitched(false)
}

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false);
  const [token, setToken] = useState();
  const [audience, setAudience] = useState(() => {
    return window.location.href.split("/")[3] === "production"
    ? process.env.REACT_APP_PRODUCTION_AUDIENCE
    : process.env.REACT_APP_SANDBOX_AUDIENCE
  });
  const [userRoles, setUserRoles] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [switchAccountId, setSwitchAccountId] = useState();
  const [isSwitched, setIsSwitched] = useState(false)
  const [switchEnvironment, setSwitchEnvironment] = useState()
  const [isSignUpFailed, setIsSignUpFailed] = useState(false)
  const [redirectToProduction, setRedirectToProduction] = useState(false)
  const [userEmail, setUserEmail] = useState();

  const audienceOptions = (audience) => {
    return ({
      redirect_uri: window.location.origin,
      onRedirectCallback: onRedirectCallback,
      audience: audience
    })
  };

  const changeAudience = useCallback(async (selectedEnvironment, currentEnvironment, requestedFromLogin) => {
    if(requestedFromLogin){
      setRedirectToProduction(requestedFromLogin);
    }
    if ((userRoles.includes('production-user') || isDashboardPersonnel(userRoles))
      && (currentEnvironment !== selectedEnvironment)) {
        setFetching(true)
        setIsSwitched(true)
        setSwitchEnvironment(selectedEnvironment)
    } else if (currentEnvironment === selectedEnvironment) {
    } else {
      setFetching(true)
      let tempURLArray = window.location.pathname.split("/")
      tempURLArray[1] = selectedEnvironment
      const newURL = tempURLArray.join("/")
      history.push(newURL)
    }
  }, [userRoles]);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      const query = window.location.search;
      if (query.includes("code=") && query.includes("state=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        let env = window.location.pathname.split("/")[1]
        const nextUser = await auth0FromHook.getUser();

        const environment = (switchEnvironment !== undefined ? switchEnvironment : env)

        const token = await auth0FromHook.getTokenSilently(audienceOptions((environment === "production" ? process.env.REACT_APP_PRODUCTION_AUDIENCE : process.env.REACT_APP_SANDBOX_AUDIENCE)))

        if (isSwitched && (userRoles.includes('production-user') || isDashboardPersonnel(userRoles))) {
          await getAccountId(token, setSwitchAccountId, setIsSwitched, switchEnvironment, redirectToProduction)
          setIsSwitched(false)
        }

        const nextUserRoles = nextUser["https://api.3dsecure.io/roles"];
        if (!shallowEqual(nextUserRoles, userRoles)) {
          setUserRoles(nextUserRoles);
        }

        const nextUserPermissions =
        nextUser["https://api.3dsecure.io/permissions"];

        if (!shallowEqual(nextUserPermissions, userPermissions)) {
          setUserPermissions(
            nextUser["https://api.3dsecure.io/permissions"]
            );
          }

          if (!shallowEqual(nextUser, user)) {
            setUser(nextUser);
          }
          setToken(token)
          setFetching(false)
        }
        setLoading(false);
      };
      initAuth0();

      // eslint-disable-next-line
    }, [switchEnvironment, window.location.pathname]);

    useEffect(() => {
      if(user && token && isAuthenticated){
        const checkAuthUser = async () => {
          if (userMetaDataWithoutId() && !isDashboardPersonnel(userRoles)) {
            try {
              const createSandboxAccount = async () => {
                const ketting = new Client(process.env.REACT_APP_SIGN_UP_ROOT + "/sandbox/accounts")
                ketting.use(bearerAuth(`${token}`))
                const kettingactive = ketting.go()
                await kettingactive.post({})
              }
              await createSandboxAccount()
            } catch (error) {
              Sentry.captureException(error)
              setIsSignUpFailed(true)
              setLoading(false)
            }
          }
        }
        checkAuthUser();
      }
    }, [user, token]);

    useEffect(() => {
      if (user && user.email != userEmail) {
        setUserEmail(user.email);
        Sentry.setUser({ email: user.email });
      }
    }, [user]);

  function userMetaDataWithoutId(){
    if (user["https://api.3dsecure.io/user"] === undefined) return true
    return false
  }

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
    setUserRoles(user["https://api.3dsecure.io/roles"]);
  };

  return (
    <>
      <Auth0Context.Provider
        value={{
          isAuthenticated,
          user,
          loading,
          fetching,
          popupOpen,
          loginWithPopup,
          audience,
          userRoles,
          userPermissions,
          token,
          isSignUpFailed,
          changeAudience,
          handleRedirectCallback,
          setIsSwitched,
          isSwitched,
          switchAccountId,
          setSwitchAccountId,
          getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
          loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
          getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
          getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
          logout: (...p) => auth0Client.logout(...p)
        }}
      >
        {children}
      </Auth0Context.Provider>
    </>
  );
};
