import styled from "styled-components";

const LogoWrapper = styled.div`
  img {
    align-self: flex-start;
    width: 80%;
    max-width: 250px;
  }
`;

export { LogoWrapper };
export default LogoWrapper;
