import React, { useState, useEffect, useCallback, useContext } from "react";
import { LoadingScreen, Grid, Card, Heading, BoxEdges, ThemeContext } from "@clearhaus/design-system";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import { useKetting } from "../../ketting/KettingContext";
import retryRequest from "../../utils/retry"
import isDashboardPersonnel from "../../utils/rolesHelper";
import * as Sentry from "@sentry/browser";
import Wrapper from "../../components/wrapper/wrapper.style";

const GridHeight = {
  height: "100vh"
};

const Login = () => {
  const { isAuthenticated, loginWithRedirect, userRoles, changeAudience } = useAuth0();
  const { ketting, setIsResourceLoading } = useKetting();
  const [isLoading, setIsLoading] = useState(true)
  const [accountId, setAccountId] = useState()
  const [error, setError] = useState(false)
  const { defaults } = useContext(ThemeContext)

  const getAccountId = useCallback(async () => {
    try {
      const rootResource = ketting.go()
      const accountResource = await rootResource.follow("accounts").followAll("accounts")
      const account = await accountResource[0].get()
      setAccountId(account.data.id)
      setIsLoading(false)
      setIsResourceLoading(false)
      sessionStorage.setItem("hasLoggedIn", true);
    } catch (error) {

      if (error.response !== undefined && await retryRequest(error, "getAccountIdRetries", getAccountId)) {
        return
      }
      Sentry.captureException(error)
      setError(true)
      setIsLoading(false)
    }
  }, [ketting, setIsResourceLoading]);

  useEffect(() => {
    if (!isAuthenticated) return loginWithRedirect({});
    if(userRoles){
      if(userRoles.includes('production-user') || isDashboardPersonnel(userRoles)){
        changeAudience('production','sandbox',true)
      }
      else {
        getAccountId(ketting, setIsLoading, setAccountId)
      }
    }

  }, [isAuthenticated, ketting, loginWithRedirect, changeAudience, userRoles, getAccountId])

  if (isLoading) {
    return (
        <Wrapper>
          <LoadingScreen/>
        </Wrapper>
    )
  }

  if(error && !isLoading) {
    return(
      <Grid container fluid>
            <Grid row justify={"center"} noGutters>
              <Grid item auto>
                <Grid
                  row
                  direction={"column"}
                  justify={"center"}
                  style={GridHeight}
                  noGutters
                >
                  <Grid item auto>
                    <Card>
                      <Card.Content>
                        <Heading level={1}>
                          <BoxEdges m={defaults.spacing[16]}>An error occured. Please refresh the page and try again.</BoxEdges>
                        </Heading>
                      </Card.Content>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
    )
  }

  if(!error && !isLoading && !(isDashboardPersonnel(userRoles) || userRoles.includes('production-user'))) {
    return(<Redirect
      to={{
        pathname: `/sandbox/accounts/${accountId}/keys`
      }}
      />)
    }
  };

export default Login;
