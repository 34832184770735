import { createGlobalStyle } from "styled-components";

import CalibreMediumWoff from '../fonts/CalibreWeb-Medium.woff';
import CalibreMediumWoff2 from '../fonts/CalibreWeb-Medium.woff2';
import CalibreRegularWoff from '../fonts/CalibreWeb-Regular.woff';
import CalibreRegularWoff2 from '../fonts/CalibreWeb-Regular.woff2';
import CalibreSemiboldWoff from '../fonts/CalibreWeb-Semibold.woff';
import CalibreSemiboldWoff2 from '../fonts/CalibreWeb-Semibold.woff2';
import CircularMediumWoff from '../fonts/lineto-circular-pro-medium.woff';
import CircularMediumWoff2 from '../fonts/lineto-circular-pro-medium.woff2';
import SourceCodeMediumWoff from '../fonts/SourceCodePro-Medium.woff';
import SourceCodeSemiboldWoff from '../fonts/SourceCodePro-Semibold.woff';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Calibre';
        src: url(${CalibreMediumWoff2}) format('woff2'),
            url(${CalibreMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Calibre';
        src: url(${CalibreSemiboldWoff2}) format('woff2'),
            url(${CalibreSemiboldWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Calibre';
        src: url(${CalibreSemiboldWoff2}) format('woff2'),
            url(${CalibreSemiboldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Calibre';
        src: url(${CalibreRegularWoff2}) format('woff2'),
            url(${CalibreRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Circular';
        src: url(${CircularMediumWoff2}) format('woff2'),
            url(${CircularMediumWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Circular';
        src: url(${CircularMediumWoff2}) format('woff2'),
            url(${CircularMediumWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'SourceCode';
        src: url(${SourceCodeMediumWoff}) format('woff');
        font-weight: medium;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'SourceCode';
        src: url(${SourceCodeSemiboldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    * {
        margin: 0;
        padding: 0;
    }

    .root {
        margin: 0;
        padding: 0;
     }
     
     h1, h2, h3, h4, h5, h6 {
        font-weight: bold !important;
     }
      
    body {
        font-family: ${props => props.theme.fontFamilies.primary}
        .root {
            margin: 0;
            padding: 0;
        }
    }
    
    .Toastify__toast {
      font-family: ${props => props.theme.fontFamilies.primary} !important;
      
      &.primary-toast {
        background-color: ${props => props.theme.colors.primary[500]} !important;
        
        div {
          color: white !important;
        }
        
        button {
          background-color: white !important;
          div {
            color: ${props => props.theme.colors.primary[500]} !important;
          }
        }
      }
`;

export default GlobalStyle;
