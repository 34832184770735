import styled from "styled-components";

const MenuItem = styled.a`
    text-decoration: none;
    transition: 0.3s;
    &:hover {
      color: ${props => props.theme.colors.primary[900]};
    }
    color: ${props => props.theme.colors.primary[500]};
    svg {
      transition: 0.3s;
      margin-right: 10px;
    }
  }
`;

const MenuItemGrid = styled.div`
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &:hover {
    svg {
      color: ${props => props.theme.colors.primary[700]};
    }
    label span {
      color: ${props => props.theme.colors.primary[900]};
    }
  }
  label {
    span {
      transition: 0.3s;
      color: ${props => props.theme.colors.primary[500]};
    }
    cursor: pointer;
  }
`;

export { MenuItem, MenuItemGrid };
export default MenuItem;
