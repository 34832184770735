import styled from "styled-components";

const Sidebar = styled.nav`
  width: 240px;
  height: calc(100vh - (${props => props.theme.spacing[32]}px * 2));
  padding: ${props => props.theme.spacing[32]}px;
  display: flex;
  flex-wrap: wrap;
  ul {
    width: 100%;
    max-height: fit-content !important;
    align-self: flex-start;
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  align-self: baseline;

  div {
    background-color: ${props =>
    props.theme.colors.accents.notice[500]} !important;
    font-size: ${props => props.theme.fontSizes[16]}px;
    font-weight: ${props => props.theme.fontWeights.medium};
    color: #ffffff;
    border: none !important;
    border-radius: 5px;
    text-transform: capitalize;
    li {
      color: #ffffff;
    }
    svg {
      color: #ffffff;
    }

    ul li {
      color: ${props => props.theme.colors.primary[500]};
      font-size: ${props => props.theme.fontSizes[16]}px;
    }
  }
`;

const BottomWrapper = styled.div`
  align-self: flex-end;
  width: 100%;
`;

const TopWrapper = styled.div`
  align-self: flex-start;
  width: 100%;
`;

export { SelectWrapper, BottomWrapper, TopWrapper };
export default Sidebar;
