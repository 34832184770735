import React, { useEffect, useContext } from "react";
import { Grid, Heading, BoxEdges, ThemeContext } from "@clearhaus/design-system";
import { Redirect, useParams } from "react-router-dom";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { GApageView } from '../tracking/tracking';

const height = {
  height: "70vh"
};

const Pending = () => {
  const { environment } = useParams()
  const { defaults } = useContext(ThemeContext)
  const { userRoles } = useAuth0();
  
  useEffect(() => {
    GApageView(environment);
  }, [environment])

  if (!userRoles.includes("pending-production-user") || environment === 'sandbox') {
    return (
      <Redirect
        to={{
          pathname: `/`
        }}
      />
    )
  }

  return (
    <>
      <Grid container fluid>
        <Grid row style={height} direction={"column"} justify={"center"}>
          <Grid row justify={"center"}>
            <Grid item auto>
              <Heading level="2">We're assessing your application</Heading>
            </Grid>
          </Grid>
          <BoxEdges m={defaults.spacing[32]}>
            <Grid row justify={"center"}>
              <Grid item auto>
                <Heading level="3">
                  You will be notified when we are done
                </Heading>
              </Grid>
            </Grid>
          </BoxEdges>
        </Grid>
      </Grid>
    </>
  );
};

export default Pending;
