import React from "react";
import LogoWrapper from "./logo.style";
import LogoImg from "../../assets/images/logo.png";

const Logo = () => {
  return (
    <>
      <LogoWrapper>
        <img src={LogoImg} alt="3dsecure.io logo" className="logo" />
      </LogoWrapper>
    </>
  );
};

export default Logo;
