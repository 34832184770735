function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const retryRequest = async (error, sessionStorageName, methodToCall) => {

    if (error.response === undefined) {
        return false;
    }

    const httpCode = error.response.status

    if (httpCode === 408 && sessionStorage.getItem(`${sessionStorageName}`) < 2) {
        if (sessionStorage.getItem(`${sessionStorageName}`) === null) {
            sessionStorage.setItem(`${sessionStorageName}`, 1)
        } else {
            sessionStorage.setItem(`${sessionStorageName}`, parseInt(sessionStorage.getItem(`${sessionStorageName}`)) + 1)
        }
        await sleep(5000)
        methodToCall()
        return true
    } else {
        sessionStorage.setItem(`${sessionStorageName}`, 0)
        return false;
    }
};

export default retryRequest;
